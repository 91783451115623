module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fugi FM","short_name":"FugiFM","start_url":"/","background_color":"#E61A04","theme_color":"#E61A04","display":"standalone","icon":"src/images/logo.svg","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d520fa5d91d14a8302014cefb11d24ec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Accueil","crumbLabelUpdates":[{"pathname":"/infos-locales","crumbLabel":"Infos locales"},{"pathname":"/emissions","crumbLabel":"Émissions"},{"pathname":"/jeux-concours","crumbLabel":"Jeux concours"},{"pathname":"/titres-diffuses","crumbLabel":"Titres diffusés"},{"pathname":"/actualites","crumbLabel":"Infos pratiques"},{"pathname":"/videos","crumbLabel":"Videos"},{"pathname":"/la-radio","crumbLabel":"La radio"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":3}}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
