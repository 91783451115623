/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { PlayerProvider } from "./src/components/context/player-context"
import { ContextProviderComposer } from "./src/components/context/context-provider-composer"
import { DirectInformationProvider } from "./src/components/context/direct-information-context"

export const wrapRootElement = ({ element }) => (
  <ContextProviderComposer contextProviders={[
    <DirectInformationProvider key={0}/>,
    <PlayerProvider key={1}/>,
  ]}>
    {element}
  </ContextProviderComposer>
)

//force reload when new build are available
export const onServiceWorkerUpdateReady = () => {
  window.location.reload(true);
};