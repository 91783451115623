import React, { useEffect, useState } from "react"
import {EventSourcePolyfill} from "event-source-polyfill/src/eventsource";

const defaultState = {
  artist: '',
  setArtist: () => {},
  title: '',
  setTitle:() => {},
}

const DirectInformationContext = React.createContext(defaultState)

const DirectInformationProvider = (props) => {
  const [artist, setArtist] = useState(null)
  const [title, setTitle] = useState(null)

  useEffect(() => {
    const url = new URL(`${process.env.MERCURE_HUB_URL}`)

    url.searchParams.append("topic", `${process.env.MERCURE_TOPIC}`)

    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${process.env.MERCURE_AUTHORIZATION}`,
      }
    });

    eventSource.onmessage = e => {
      setArtist(JSON.parse(e.data).artist)
      setTitle(JSON.parse(e.data).title)
    }

    fetch(`${process.env.API_URL}/jsonapi/node/titre_joue?page[limit]=1&sort=-created`, {
      headers: new Headers({
        "Content-Type": "application/vnd.api+json",
        "Accept": "application/vnd.api+json",
        "Authorization": "Basic `${process.env.API_AUTHORIZATION}`",
      }),
    })
    .then(response => response.json())
    .then(resultData => {
      setArtist(resultData.data[0]?.attributes.field_artiste)
      setTitle(resultData.data[0]?.attributes.title)
    })
  }, [])

  return (
    <DirectInformationContext.Provider value={{
      artist,
      title,
    }}>
      {props.children}
    </DirectInformationContext.Provider>
  )
}

export { DirectInformationContext, DirectInformationProvider }
