import React, {useEffect, useState} from "react"

export const DIRECT_TYPE = 1
export const PODCAST_TYPE = 2
export const VIDEO_TYPE = 3
export const SHOW_TYPE = 4

const defaultState = {
    audio: {},
    content: {},
    setContent: () => {
    },
    play: false,
    setPlay: () => {
    },
    mute: false,
    setMute: () => {
    },
    enabled: false,
    setEnabled: () => {
    },
    load: false,
    setLoad: () => {
    },
}

const PlayerContext = React.createContext(defaultState)

const PlayerProvider = (props) => {
    const [audio] = useState(new Audio())
    const [content, setContent] = useState({type: null, description: null})
    const [play, setPlay] = useState(false)
    const [mute, setMute] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [load, setLoad] = useState(false)

    useEffect(() => {
        audio.addEventListener('loadstart', () => {
            setLoad(true)
        });

        audio.addEventListener('canplay', () => {
            setLoad(false)
        });
    }, [])

    useEffect(() => {
        if (enabled) {
            document.documentElement.classList.add('player-open');
        }
    }, [enabled])

    return (
        <PlayerContext.Provider value={{
            setContent,
            content,
            enabled,
            setEnabled,
            audio,
            setPlay,
            play,
            setMute,
            mute,
            load,
            setLoad,
        }}>
            {props.children}
        </PlayerContext.Provider>
    )
}

export {PlayerContext, PlayerProvider}